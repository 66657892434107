<template>
  <div
    v-if="businessRef.total_balance !== undefined"
    id="business-account-balance"
    class="row"
  >
    <div class="col-12 line-height-2">
      <strong class="font-24">${{ withCommas(businessCreditsBalance) }}</strong> is your current balance.<br>
    </div>
    <ElAlert
      v-if="pendingBalanceRef"
      type="warning"
      :closable="false"
      show-icon
      class="col-12 py-3 my-3"
    >
      A <strong>${{ pendingBalanceRef.amount }}</strong> transfer was started on {{ momentUtil(pendingBalanceRef.created_at, momentUtil.tz.guess()).toDisplayMonthAndDayFormat() }} at {{ momentUtil(pendingBalanceRef.created_at, momentUtil.tz.guess()).toDisplayShortTimeFormat() }} and is currently being processed. Once completed, it will be added to your account balance.<br><br>
      You will be able to add more funds and adjust your auto recharge settings after this transfer is complete.
    </ElAlert>
    <div class="col-12">
      <ElAlert
        v-if="!uiFlags.hasACH"
        type="info"
        show-icon
        class="py-3 mb-3"
        :closable="false"
      >
        In order to add funds or enable auto-recharge, you need to add your bank account details below.  This will allow balances to be maintained via ACH.
      </ElAlert>
      <SdACHForm
        :editable="isOwner && !pendingBalanceRef"
        @input="()=>uiFlags.hasACH = true"
      />
    </div>
    <div class="col-9">
      <h3 class="mb-0">
        Add Funds
      </h3>
      <p>
        Make a one-time payment to create an account balance.
      </p>
    </div>
    <div class="col-3 d-flex justify-content-end align-items-start">
      <ElButton
        :disabled="!isOwner || pendingBalanceRef || businessCreditsBalance >= MaxCreditBalance || !uiFlags.hasACH"
        size="small"
        class="mt-2"
        @click="openAddCreditDialog"
      >
        Add Funds
      </ElButton>
    </div>
    <div class="col-9">
      <h3 class="mb-0">
        Auto Recharge
      </h3>
      <p>Automatically top up your account balance when it falls below a specific threshold.</p>
      <template v-if="rechargeCreditsEnabled">
        <div v-if="editingRechargeCredits">
          <p>
            Your bank account will be automatically recharged if the balance falls below
            <strong>${{ CreditRechargeThreshold }}</strong>. Future charges for Showdigs services will use this balance first.
          </p>
          <div v-if="businessCreditsBalance < CreditRechargeThreshold">
            <ElAlert
              type="warning"
              show-icon
              class="mb-3"
              :closable="false"
              tag-class="tagline-warning-light"
            >
              <div>
                Your balance is currently below <strong>${{ CreditRechargeThreshold }}</strong>.
                By enabling auto recharge, your bank account will be charged for
                the entered recharge amount. Payment processing might take up to 4 business days.
              </div>
            </ElAlert>
          </div>
          <div class="row pt-4">
            <div class="col-12 mt-0 pt-0">
              <SdFloatLabel label="Recharge amount">
                <ElSelect
                  v-model="uiFlags.rechargeAmount"
                  placeholder="Recharge amount"
                >
                  <ElOption
                    v-for="option in CreditRechargeAmounts"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  />
                </ElSelect>
              </SdFloatLabel>
            </div>
            <div class="col-6 pt-2">
              <ElButton
                :class="$viewport.lt.md ? 'w-100 ml-0' :''"
                class="w-100 mt-4"
                size="small"
                @click="handleCancelEdit"
              >
                Cancel
              </ElButton>
            </div>
            <div class="col-6 pt-2">
              <ElButton
                :class="$viewport.lt.md ? 'w-100 ml-0' :''"
                :disabled="!uiFlags.rechargeAmount"
                :loading="uiFlags.loading"
                class="w-100 mt-4"
                size="small"
                type="primary"
                @click="saveRechargeAmount"
              >
                Save
              </ElButton>
            </div>
          </div>
        </div>
        <div
          v-else-if="uiFlags.rechargeAmount"
          class="py-0"
        >
          <div class="col-3 p-0 my-0">
            <ElButton
              :class="$viewport.lt.md ? 'w-100 ml-0' :''"
              class="my-0"
              size="small"
              :disabled="Boolean(pendingBalanceRef)"
              @click="editingRechargeCredits = true"
            >
              Edit Recharge Amount
            </ElButton>
          </div>
        </div>
      </template>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <ElSwitch
        v-model="rechargeCreditsEnabled"
        :disabled="!isOwner|| pendingBalanceRef || !uiFlags.hasACH"
        :width="44"
        class="mt-2"
        @change="rechargeCreditsEnabledChanged"
      />
    </div>
    <AddBusinessCreditsDialog
      ref="addBusinessCreditsDialog"
      @input="(value) => pendingBalanceRef=value"
    />
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import { CreditRechargeAmounts, CreditRechargeThreshold, MaxCreditBalance } from '@/constants/Billing';
import { withCommas } from '@/utils/NumberUtil';
import Role from '@/constants/Role';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import SdACHForm from '@/components/common/form/SdACHForm.vue';
import AddBusinessCreditsDialog from '@/components/billing/AddBusinessCreditsDialog.vue';
import SdFloatLabel from '../common/form/SdFloatLabel';
import momentUtil from '../../utils/MomentUtil';

export default {
  name: 'BusinessAccountBalance',
  components: { AddBusinessCreditsDialog, SdFloatLabel, SdACHForm },
  setup(props, context) {
    const store = context.root.$store;
    const authUser = store.state.Auth.user;
    const businessCreditsBalance = computed(() => store.getters['Business/businessCreditsBalance']);
    const dispatch = store.dispatch;
    const uiFlags = reactive({
      rechargeAmount: null,
      loading: false,
      hasACH: false,
    });
    const businessRef = ref({});
    const rechargeCreditsEnabled = ref(false);
    const editingRechargeCredits = ref(false);
    const rechargeAmountSaved = ref(null);
    const isOwner = authUser.role === Role.ACCOUNT_OWNER;
    const pendingBalanceRef = ref(null);

    init();

    return {
      uiFlags,
      businessRef,
      pendingBalanceRef,
      businessCreditsBalance,
      withCommas,
      isOwner,
      rechargeCreditsEnabled,
      CreditRechargeAmounts,
      saveRechargeAmount,
      momentUtil,
      openAddCreditDialog,
      rechargeCreditsEnabledChanged,
      CreditRechargeThreshold,
      MaxCreditBalance,
      editingRechargeCredits,
      handleCancelEdit,
      rechargeAmountSaved,
    };

    async function init() {
      businessRef.value = await dispatch('Business/get');
      pendingBalanceRef.value = businessRef.value.pending_balance_transaction;
      uiFlags.rechargeAmount = businessRef.value.recurring_credits_amount;
      uiFlags.hasACH = businessRef.value.stripe_ach_method;
      rechargeCreditsEnabled.value = !!uiFlags.rechargeAmount;
      rechargeAmountSaved.value = uiFlags.rechargeAmount;
      editingRechargeCredits.value = !rechargeCreditsEnabled.value;
    }
    async function saveRechargeAmount() {
      uiFlags.loading = true;
      try {
        await context.root.$store.dispatch('Billing/saveRechargeAmount', uiFlags.rechargeAmount);
        let message = 'Auto recharge has been disabled';
        if (uiFlags.rechargeAmount) {
          message = 'Recharge amount has been saved';
          if (businessCreditsBalance.value < CreditRechargeThreshold) {
            message += ', Funds will be added to your balance once the ACH bank account transfer is successfully processed.';
            pendingBalanceRef.value = await context.root.$store.dispatch('Billing/addBusinessCredits', uiFlags.rechargeAmount);
          }
        }
        editingRechargeCredits.value = false;
        rechargeAmountSaved.value = uiFlags.rechargeAmount;
        showSuccessNotify(context, message, { duration: 10000 });
      } catch (error) {
        showErrorNotify(context, error.message || 'Something went wrong');
      }
      uiFlags.loading = false;
    }

    async function rechargeCreditsEnabledChanged() {
      if (!rechargeCreditsEnabled.value) {
        uiFlags.rechargeAmount = null;
        editingRechargeCredits.value = false;
        await saveRechargeAmount();
      } else {
        editingRechargeCredits.value = true;
      }
    }

    function openAddCreditDialog() {
      context.refs.addBusinessCreditsDialog.open();
    }
    function edit() {
      editingRechargeCredits.value = true;
    }

    function handleCancelEdit() {
      if (editingRechargeCredits.value) {
        editingRechargeCredits.value = false;
        if (!uiFlags.rechargeAmount || !rechargeAmountSaved.value) {
          rechargeCreditsEnabled.value = false;
          uiFlags.rechargeAmount = null;
        } else {
          uiFlags.rechargeAmount = rechargeAmountSaved.value;
        }
      }
    }
  },
};
</script>

<style lang="scss">
#business-account-balance {
  .payment-method {
    border-radius: $app-border-radius;
  }

  #payment-method-exists {
    min-height: 56px;
  }
}
</style>
